import React, { useState, useEffect, useRef } from 'react';
import { Search, MapPin, DollarSign, Pencil, Info, Check } from 'lucide-react';
import Map, { NavigationControl } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGFubnk3MDkiLCJhIjoiY20zMTAzNjAwMHU5ODJsb3FjZzZyOTAxNiJ9.wqczPQfQGoVTQDC7-p01GQ';

const DrawingMap = ({ userLocation, onRegionChange }) => {
    const mapRef = useRef(null);
    const drawRef = useRef(null);
    const [viewState, setViewState] = useState({
      longitude: userLocation?.longitude || -71.0589,
      latitude: userLocation?.latitude || 42.3601,
      zoom: 14
    });
  
    useEffect(() => {
      if (!mapRef.current || !userLocation) return;
      
      // Center map on user location
      setViewState({
        longitude: userLocation.longitude,
        latitude: userLocation.latitude,
        zoom: 14
      });
      
      // Custom draw styles
      const drawStyles = [
        // Style for the points during drawing
        {
          'id': 'gl-draw-point',
          'type': 'circle',
          'filter': ['all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point']
          ],
          'paint': {
            'circle-radius': 5,
            'circle-color': '#fff'
          }
        },
        // Style for the polygon fill
        {
          'id': 'gl-draw-polygon-fill',
          'type': 'fill',
          'filter': ['all', ['==', '$type', 'Polygon']],
          'paint': {
            'fill-color': '#22c55e',
            'fill-outline-color': '#22c55e',
            'fill-opacity': 0.2
          }
        },
        // Style for the polygon outline when drawing
        {
          'id': 'gl-draw-polygon-stroke-active',
          'type': 'line',
          'filter': ['all',
            ['==', '$type', 'Polygon'],
            ['==', 'active', 'true']
          ],
          'paint': {
            'line-color': '#22c55e',
            'line-width': 2,
            'line-dasharray': [2, 2]
          }
        },
        // Style for the polygon outline
        {
          'id': 'gl-draw-polygon-stroke',
          'type': 'line',
          'filter': ['all', ['==', '$type', 'Polygon']],
          'paint': {
            'line-color': '#22c55e',
            'line-width': 2
          }
        }
      ];
  
      // Create custom draw control
      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        // Only show our custom controls
        controls: {
          polygon: true,
          trash: true
        },
        defaultMode: 'draw_polygon',
        styles: drawStyles,
        userProperties: true
      });
      
      mapRef.current.addControl(drawRef.current);
  
      // Add custom draw button
      const drawButton = document.createElement('button');
      drawButton.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-draw-polygon';
      drawButton.innerHTML = `
        <div class="flex items-center gap-2 px-3 py-2 bg-black/75 rounded-full text-white">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M12 19l7-7 3 3-7 7-3-3z"></path>
            <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
            <path d="M2 2l7.586 7.586"></path>
            <path d="M11 11l-4 4"></path>
          </svg>
          <span>Draw Region</span>
        </div>
      `;
      drawButton.onclick = () => {
        drawRef.current.changeMode('draw_polygon');
      };
  
      const drawContainer = document.createElement('div');
      drawContainer.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
      drawContainer.appendChild(drawButton);
      mapRef.current.getContainer().appendChild(drawContainer);
  
      const updateArea = (e) => {
        const data = drawRef.current.getAll();
        if (data.features.length > 0) {
          const polygon = data.features[0];
          const center = turf.center(polygon);
          const maxDistance = turf.pointsWithinPolygon(
            turf.randomPoint(100, { bbox: turf.bbox(polygon) }),
            polygon
          ).features.reduce((max, point) => {
            const distance = turf.distance(
              center,
              point,
              { units: 'miles' }
            );
            return Math.max(max, distance);
          }, 0);
  
          if (maxDistance > 2.5) {
            drawRef.current.delete(polygon.id);
            alert('Selected area is too large. Please draw a smaller region (max 5 mile diameter).');
            return;
          }
  
          onRegionChange({
            bounds: polygon,
            center: center.geometry.coordinates,
            maxDistance
          });
        } else {
          onRegionChange(null);
        }
      };
  
      mapRef.current.on('draw.create', updateArea);
      mapRef.current.on('draw.update', updateArea);
      mapRef.current.on('draw.delete', updateArea);
  
      return () => {
        if (mapRef.current && drawRef.current) {
          mapRef.current.removeControl(drawRef.current);
          const customButton = mapRef.current.getContainer().querySelector('.mapboxgl-ctrl-draw-polygon');
          if (customButton) {
            customButton.remove();
          }
        }
      };
    }, [userLocation]);
  
    return (
      <div className="h-64 rounded-lg overflow-hidden relative">
        <Map
          ref={mapRef}
          mapboxAccessToken={MAPBOX_TOKEN}
          {...viewState}
          onMove={evt => setViewState(evt.viewState)}
          style={{ width: '100%', height: '100%' }}
          mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
          minZoom={13}
          maxBounds={[
            [userLocation.longitude - 0.1, userLocation.latitude - 0.1],
            [userLocation.longitude + 0.1, userLocation.latitude + 0.1]
          ]}
        >
          <NavigationControl />
        </Map>
      </div>
    );
  };

const PostRequestForm = ({ 
  onSubmit,
  onClose,
  userLocation,
  initialValues = {}
}) => {
  const [formState, setFormState] = useState({
    question: initialValues.question || '',
    reward: initialValues.reward || '',
    timeLimit: initialValues.timeLimit || '1',
    scope: initialValues.scope || 'local',
    location: '',
    radius: initialValues.radius || '5',
    locationSuggestions: [],
    latitude: userLocation?.latitude || null,
    longitude: userLocation?.longitude || null,
    boundaryType: 'radius',
    customBounds: null,
    isAnonymous: false
  });

  const [locationError, setLocationError] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(
    userLocation ? {
      place_name: 'Current Location',
      coordinates: [userLocation.longitude, userLocation.latitude]
    } : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (userLocation && formState.scope === 'local' && !selectedLocation) {
      setSelectedLocation({
        place_name: 'Current Location',
        coordinates: [userLocation.longitude, userLocation.latitude]
      });
      setFormState(prev => ({
        ...prev,
        location: 'Current Location',
        latitude: userLocation.latitude,
        longitude: userLocation.longitude
      }));
    }
  }, [formState.scope, userLocation]);

  const handleRegionChange = (region) => {
    if (region) {
      setFormState(prev => ({
        ...prev,
        customBounds: region.bounds,
        latitude: region.center[1],
        longitude: region.center[0]
      }));
    }
  };

  const handleLocationSearch = async (searchText) => {
    if (!searchText || searchText === 'Current Location') {
      setFormState(prev => ({ ...prev, locationSuggestions: [] }));
      return;
    }

    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchText)}.json?` +
        `access_token=${MAPBOX_TOKEN}&` +
        `proximity=${userLocation ? `${userLocation.longitude},${userLocation.latitude}` : ''}&` +
        'types=address,poi&' +
        'country=US'
      );

      const data = await response.json();
      
      let filteredFeatures = data.features;
      
      if (formState.scope === 'local' && userLocation) {
        filteredFeatures = data.features.filter(feature => {
          const distance = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            feature.center[1],
            feature.center[0]
          );
          return distance <= 5;
        });

        if (filteredFeatures.length === 0) {
          setLocationError('Location must be within 5 miles of your current location');
        } else {
          setLocationError('');
        }
      }

      setFormState(prev => ({
        ...prev,
        locationSuggestions: filteredFeatures.map(feature => ({
          place_name: feature.place_name,
          coordinates: feature.center,
          street: feature.text,
          context: feature.context?.map(c => c.text).join(', ')
        }))
      }));
    } catch (error) {
      console.error('Error searching locations:', error);
      setLocationError('Error searching for locations');
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 3959; // Earth's radius in miles
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return R * c;
  };

  const handleInputChange = (field, value) => {
    setFormState(prev => {
      const newState = { ...prev, [field]: value };
      
      if (field === 'scope') {
        if (value === 'local') {
          if (userLocation) {
            setSelectedLocation({
              place_name: 'Current Location',
              coordinates: [userLocation.longitude, userLocation.latitude]
            });
            newState.location = 'Current Location';
            newState.latitude = userLocation.latitude;
            newState.longitude = userLocation.longitude;
            newState.radius = '5';
          }
        } else {
          newState.location = '';
          newState.locationSuggestions = [];
          newState.customBounds = null;
          setSelectedLocation(null);
        }
        setLocationError('');
      }

      if (field === 'boundaryType') {
        newState.customBounds = null;
      }
      
      return newState;
    });
  };

  const handleLocationSelect = (suggestion) => {
    setSelectedLocation(suggestion);
    setFormState(prev => ({
      ...prev,
      location: suggestion.place_name,
      locationSuggestions: [],
      latitude: suggestion.coordinates[1],
      longitude: suggestion.coordinates[0],
      customBounds: null
    }));
    setLocationError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formState.scope === 'local') {
      if (!selectedLocation && formState.boundaryType === 'radius') {
        setLocationError('Please select a valid location');
        return;
      }
      
      if (formState.boundaryType === 'custom' && !formState.customBounds) {
        setLocationError('Please draw a region on the map');
        return;
      }
    }

    setIsSubmitting(true);
    try {
      await onSubmit({
        ...formState,
        author: formState.scope === 'global' && formState.isAnonymous ? 'Anonymous' : '@you',
        location: selectedLocation?.place_name || formState.location,
        latitude: selectedLocation?.coordinates[1] || formState.latitude,
        longitude: selectedLocation?.coordinates[0] || formState.longitude
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setLocationError('Error submitting request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
      <div>
        <label className="block text-gray-400 mb-2">Request ideas for</label>
        <textarea
          value={formState.question}
          onChange={(e) => handleInputChange('question', e.target.value)}
          className="w-full min-h-[120px] bg-[#1f2637] rounded-lg p-4 text-white 
                    border border-[#2a3142] focus:outline-none focus:border-orange-500
                    resize-none"
          placeholder="What would you like to know?"
          required
        />
      </div>

      <div>
        <label className="block text-gray-400 mb-2">Reward Amount</label>
        <div className="relative">
          <div className="absolute left-4 top-1/2 -translate-y-1/2 text-green-400">
            <DollarSign size={20} />
          </div>
          <input
            type="number"
            value={formState.reward}
            onChange={(e) => handleInputChange('reward', e.target.value)}
            className="w-full bg-[#1f2637] rounded-lg p-4 pl-12 text-green-400 font-medium
                     border border-[#2a3142] focus:outline-none focus:border-orange-500"
            placeholder="0.00"
            step="0.01"
            min="0.01"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-gray-400 mb-2">Time Limit</label>
        <select
          value={formState.timeLimit}
          onChange={(e) => handleInputChange('timeLimit', e.target.value)}
          className="w-full bg-[#1f2637] rounded-lg p-4 text-white 
                   border border-[#2a3142] focus:outline-none focus:border-orange-500 
                   appearance-none cursor-pointer"
        >
          <option value="1">1 hour</option>
          <option value="3">3 hours</option>
          <option value="6">6 hours</option>
          <option value="12">12 hours</option>
          <option value="24">24 hours</option>
        </select>
      </div>

      <div>
        <label className="block text-gray-400 mb-2">Scope</label>
        <div className="flex gap-6 mb-2">
          <label className="relative flex items-center cursor-pointer group">
            <input
              type="radio"
              name="scope"
              value="local"
              checked={formState.scope === 'local'}
              onChange={(e) => handleInputChange('scope', e.target.value)}
              className="peer sr-only"
            />
            <div className="w-5 h-5 border-2 border-gray-400 rounded-full 
                          peer-checked:border-orange-500 peer-checked:bg-orange-500 
                          transition-all"></div>
            <span className="text-white ml-2 group-hover:text-orange-500 transition-colors">
              Local Only
            </span>
          </label>
          <label className="relative flex items-center cursor-pointer group">
            <input
              type="radio"
              name="scope"
              value="global"
              checked={formState.scope === 'global'}
              onChange={(e) => handleInputChange('scope', e.target.value)}
              className="peer sr-only"
            />
            <div className="w-5 h-5 border-2 border-gray-400 rounded-full 
                          peer-checked:border-orange-500 peer-checked:bg-orange-500 
                          transition-all"></div>
            <span className="text-white ml-2 group-hover:text-orange-500 transition-colors">
              Global
            </span>
          </label>
        </div>

        <div className="text-gray-400 text-sm mb-4 flex items-start gap-2">
          <Info size={16} className="flex-shrink-0 mt-0.5" />
          <span>
            {formState.scope === 'local' 
              ? "Local requests are only visible to users within your selected area"
              : "Global requests are visible to all users worldwide"}
          </span>
        </div>

        {formState.scope === 'global' && (
          <div className="mt-4">
            <label className="flex items-center cursor-pointer group">
              <input
                type="checkbox"
                checked={formState.isAnonymous}
                onChange={(e) => handleInputChange('isAnonymous', e.target.checked)}
                className="sr-only peer"
              />
              <div className="w-5 h-5 border-2 border-gray-400 rounded 
                            peer-checked:border-orange-500 peer-checked:bg-orange-500 
                            transition-all flex items-center justify-center">
                {formState.isAnonymous && <Check size={12} className="text-white" />}
              </div>
              <span className="text-white ml-2 group-hover:text-orange-500 transition-colors">
                Post Anonymously
              </span>
            </label>
            <p className="text-gray-400 text-sm mt-2 ml-7">
              Your request will be visible globally but your identity will be hidden
            </p>
          </div>
        )}

        {formState.scope === 'local' && !userLocation && (
          <div className="mt-4 p-4 bg-red-500/10 rounded-lg border border-red-500/20">
            <p className="text-red-400 text-sm flex items-center gap-2">
              <Info size={16} />
              Location services must be enabled for local requests
            </p>
          </div>
        )}

        {formState.scope === 'local' && userLocation && (
          <div className="mt-4 space-y-4">
            <div>
              <label className="block text-gray-400 mb-2">Boundary Type</label>
              <div className="flex gap-6 mb-4">
                <label className="relative flex items-center cursor-pointer group">
                  <input
                    type="radio"
                    name="boundaryType"
                    value="radius"
                    checked={formState.boundaryType === 'radius'}
                    onChange={(e) => handleInputChange('boundaryType', e.target.value)}
                    className="peer sr-only"
                  />
                  <div className="w-5 h-5 border-2 border-gray-400 rounded-full 
                                peer-checked:border-orange-500 peer-checked:bg-orange-500 
                                transition-all"></div>
                  <span className="text-white ml-2 group-hover:text-orange-500 transition-colors flex items-center gap-2">
                    <span>Radius</span>
                    <span className="text-gray-400 text-sm">(circular area around a point)</span>
                  </span>
                </label>
                <label className="relative flex items-center cursor-pointer group">
                  <input
                    type="radio"
                    name="boundaryType"
                    value="custom"
                    checked={formState.boundaryType === 'custom'}
                    onChange={(e) => handleInputChange('boundaryType', e.target.value)}
                    className="peer sr-only"
                  />
                  <div className="w-5 h-5 border-2 border-gray-400 rounded-full 
                                peer-checked:border-orange-500 peer-checked:bg-orange-500 
                                transition-all"></div>
                  <span className="text-white ml-2 group-hover:text-orange-500 transition-colors flex items-center gap-2">
                    <span>Custom Region</span>
                    <span className="text-gray-400 text-sm">(draw your own area)</span>
                  </span>
                </label>
              </div>

              {formState.boundaryType === 'radius' ? (
                <>
                  <label className="block text-gray-400 mb-2">Location</label>
                  <div className="relative">
                    <input
                      type="text"
                      value={formState.location}
                      onChange={(e) => handleInputChange('location', e.target.value)}
                      placeholder={userLocation ? "Using current location" : "Search for a location"}
                      className="w-full bg-[#1f2637] rounded-lg p-4 pl-12 text-white 
                              border border-[#2a3142] focus:outline-none focus:border-orange-500"
                    />
                    <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                    
                    {locationError && (
                      <div className="text-red-500 text-sm mt-1">{locationError}</div>
                    )}
                    
                    {formState.locationSuggestions.length > 0 && (
                      <div className="absolute w-full bg-[#1f2637] mt-2 rounded-lg border border-[#2a3142] 
                                    overflow-hidden z-50 max-h-60 overflow-y-auto">
                        {formState.locationSuggestions.map((suggestion, index) => (
                          <button
                            key={index}
                            type="button"
                            className="w-full p-3 text-left text-white hover:bg-[#2a3142] flex items-center gap-2"
                            onClick={() => handleLocationSelect(suggestion)}
                          >
                            <MapPin size={16} className="text-gray-400 flex-shrink-0" />
                            <div className="flex flex-col">
                              <span className="text-white">{suggestion.street}</span>
                              <span className="text-gray-400 text-sm">{suggestion.context}</span>
                            </div>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <div className="flex justify-between">
                      <label className="block text-gray-400 mb-2">Radius (miles)</label>
                      <span className="text-gray-400 text-sm">People within this range will see your request</span>
                    </div>
                    <input
                      type="range"
                      min="1"
                      max="5"
                      value={formState.radius}
                      onChange={(e) => handleInputChange('radius', e.target.value)}
                      className="w-full accent-orange-500"
                    />
                    <div className="flex justify-between text-gray-400 text-sm mt-1">
                      <span>1 mile</span>
                      <span>{formState.radius} miles</span>
                      <span>5 miles</span>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <p className="text-gray-400 mb-2 flex items-center gap-2">
                    <Pencil size={16} />
                    Draw your custom region on the map (max 5 mile diameter)
                  </p>
                  <DrawingMap 
                    userLocation={userLocation}
                    onRegionChange={handleRegionChange}
                  />
                  {formState.customBounds && (
                    <p className="text-gray-400 text-sm mt-2 flex items-center gap-2">
                      <Info size={16} />
                      Area diameter: {Math.round(turf.area(formState.customBounds) / 2589988.11 * 2)} miles
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex gap-4 pt-4">
        <button
          type="button"
          onClick={onClose}
          className="flex-1 py-3 rounded-lg text-white hover:bg-[#262d40] transition-colors"
          disabled={isSubmitting}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="flex-1 py-3 rounded-lg bg-orange-500 text-white hover:bg-orange-600 transition-colors
                   disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isSubmitting || (formState.scope === 'local' && !userLocation)}
        >
          {isSubmitting ? 'Posting...' : 'Post Request'}
        </button>
      </div>
    </form>
  );
};

export default PostRequestForm;