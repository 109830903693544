import React, { useState, useEffect, useRef } from 'react';
import { X, Navigation2, Pencil } from 'lucide-react';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getFirestore, collection, addDoc, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import PostRequestForm from './PostRequestForm';
import * as turf from '@turf/turf';

const firebaseConfig = {
  apiKey: "AIzaSyC8mgwsJwXSa6mLkSw6q5QX0ssek-l5E5U",
  authDomain: "locallens-5cf17.firebaseapp.com",
  databaseURL: "https://locallens-5cf17-default-rtdb.firebaseio.com",
  projectId: "locallens-5cf17",
  storageBucket: "locallens-5cf17.firebasestorage.app",
  messagingSenderId: "876205328855",
  appId: "1:876205328855:web:10b96a98b2dc981c126392",
  measurementId: "G-5108XLEPC0"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGFubnk3MDkiLCJhIjoiY20zMTAzNjAwMHU5ODJsb3FjZzZyOTAxNiJ9.wqczPQfQGoVTQDC7-p01GQ';

const calculateWinLikelihood = (ideaCount, reward) => {
  if (ideaCount === 0) return 100;
  const baseOdds = 100 / (1 + (ideaCount / 10));
  const rewardFactor = Math.log(parseFloat(reward.replace('$', '')) + 1) / Math.log(100);
  return Math.min(Math.round(baseOdds * (1 + rewardFactor)), 100);
};

const calculateTotalMarketValue = (requests) => {
  return requests.reduce((total, request) => {
    const amount = parseFloat(request.currentPrice.replace('$', ''));
    return total + amount;
  }, 0);
};

const App = () => {
  const [viewState, setViewState] = useState({
    longitude: -71.0589,
    latitude: 42.3601,
    zoom: 13
  });
  
  const [userLocation, setUserLocation] = useState(null);
  const [locationStatus, setLocationStatus] = useState('pending');
  const [activeTab, setActiveTab] = useState('requests');
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [totalMarketValue, setTotalMarketValue] = useState(0);
  const mapRef = useRef(null);
  const drawRef = useRef(null);

  useEffect(() => {
    const requestsRef = collection(db, "requests");
    const unsubscribe = onSnapshot(requestsRef, (snapshot) => {
      const loadedRequests = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRequests(loadedRequests);
      setTotalMarketValue(calculateTotalMarketValue(loadedRequests));
    });

    return () => unsubscribe();
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLocationStatus('requesting');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setUserLocation(newLocation);
          setViewState(prev => ({
            ...prev,
            ...newLocation,
            zoom: 15
          }));
          setLocationStatus('granted');
        },
        (error) => {
          console.error('Error getting location:', error);
          setLocationStatus('denied');
        }
      );
    } else {
      setLocationStatus('unsupported');
    }
  };

  const centerMapOnUser = () => {
    if (userLocation) {
      setViewState(prev => ({
        ...prev,
        longitude: userLocation.longitude,
        latitude: userLocation.latitude,
        zoom: 15
      }));
    }
  };

  const handlePostRequest = async (formData) => {
    if (!userLocation) {
      alert('Please enable location services to post a request');
      return;
    }

    const newRequest = {
      author: '@you',
      posted: 'just now',
      originalPrice: `$${formData.reward}`,
      currentPrice: `$${formData.reward}`,
      question: formData.question,
      timeLeft: `${formData.timeLimit} hours`,
      ideaCount: 0,
      scope: formData.scope,
      location: formData.scope === 'local' ? {
        latitude: formData.latitude,
        longitude: formData.longitude
      } : null,
      radius: formData.scope === 'local' ? parseInt(formData.radius) : null,
      customBounds: formData.customBounds || null,
      createdAt: new Date().toISOString()
    };

    try {
      await addDoc(collection(db, "requests"), newRequest);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Error posting request. Please try again.');
    }
  };

  const handleCircleClick = (request) => {
    setSelectedRequest(request.id === selectedRequest?.id ? null : request);
  };

  const calculatePixelRadius = (meters, latitude, zoom) => {
    const earthCircumference = 40075017;
    const latitudeRadians = latitude * (Math.PI / 180);
    const metersPerPixel = (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoom + 8);
    return meters / metersPerPixel;
  };

  const PostRequestModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black/95 flex items-center justify-center z-50 p-4 overflow-y-auto">
        <div className="bg-[#1a1f2e] rounded-xl w-full max-w-lg my-8">
          <div className="flex justify-between items-center p-6 border-b border-gray-700">
            <h2 className="text-xl text-white">Post a Request</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={20} />
            </button>
          </div>

          <div className="p-6">
            <PostRequestForm
              onSubmit={handlePostRequest}
              onClose={onClose}
              userLocation={userLocation}
              mapboxToken={MAPBOX_TOKEN}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto p-6">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-3xl font-bold mb-2">localens</h1>
            <p className="text-gray-400">
              An open marketplace for ideas.
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-400">Total Market Value</p>
            <p className="text-2xl font-bold text-green-400">
              ${totalMarketValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
          </div>
        </div>
        
        {/* GPS Notice */}
        {locationStatus === 'pending' && (
          <button 
            onClick={getUserLocation}
            className="bg-zinc-800 text-white rounded-full px-6 py-2 mb-6 inline-block hover:bg-zinc-700 transition-colors"
          >
            Click to enable location services
          </button>
        )}
        
        {/* Auth Buttons */}
        <div className="flex gap-4 mb-8">
          <button className="px-6 py-2 bg-white text-black rounded-full font-medium">Sign Up</button>
          <button className="px-6 py-2 bg-white text-black rounded-full font-medium">Sign In</button>
        </div>
        
        {/* Tabs */}
        <div className="flex gap-8 border-b border-gray-700 mb-6">
          <button 
            onClick={() => setActiveTab('requests')}
            className={`pb-4 ${activeTab === 'requests' ? 'text-white border-b-2 border-white font-medium' : 'text-gray-400'}`}
          >
            Requests
          </button>
          <button 
            onClick={() => setActiveTab('ideas')}
            className={`pb-4 ${activeTab === 'ideas' ? 'text-white border-b-2 border-white font-medium' : 'text-gray-400'}`}
          >
            Ideas
          </button>
          <button 
            onClick={() => setActiveTab('saved')}
            className={`pb-4 ${activeTab === 'saved' ? 'text-white border-b-2 border-white font-medium' : 'text-gray-400'}`}
          >
            Saved
          </button>
        </div>

        {/* Map Area */}
        {activeTab === 'requests' && (
          <>
            <div className="relative h-96 rounded-lg mb-6 overflow-hidden">
              <Map
                ref={mapRef}
                mapboxAccessToken={MAPBOX_TOKEN}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{ width: '100%', height: '100%' }}
                mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
              >
                {userLocation && (
                  <Marker longitude={userLocation.longitude} latitude={userLocation.latitude}>
                    <div className="bg-orange-500 text-white px-4 py-1 rounded-full font-medium shadow-lg -translate-x-1/2 -translate-y-1/2">
                      You
                    </div>
                  </Marker>
                )}

                {requests.map(request => {
                  if (request.scope === 'local' && request.location) {
                    const isSelected = selectedRequest?.id === request.id;
                    
                    if (request.customBounds) {
                      // Render custom region
                      return (
                        <Source
                          key={request.id}
                          type="geojson"
                          data={request.customBounds}
                        >
                          <Layer
                            id={`custom-area-${request.id}`}
                            type="fill"
                            paint={{
                              'fill-color': isSelected ? '#22c55e' : '#000000',
                              'fill-opacity': isSelected ? 0.2 : 0.15,
                              'fill-outline-color': isSelected ? '#22c55e' : '#000000'
                            }}
                          />
                        </Source>
                      );
                    } else {
                      // Render radius circle
                      const radiusInMeters = request.radius * 1609.34;
                      const circleRadius = calculatePixelRadius(
                        radiusInMeters,
                        request.location.latitude,
                        viewState.zoom
                      );

                      return (
                        <React.Fragment key={request.id}>
                          <Marker
                            longitude={request.location.longitude}
                            latitude={request.location.latitude}
                          >
                            <div 
                              onClick={() => handleCircleClick(request)}
                              className={`bg-black text-white px-3 py-1 rounded-full shadow-lg -translate-x-1/2 -translate-y-1/2 cursor-pointer transition-all
                                      ${isSelected ? 'scale-125 bg-green-500' : 'hover:scale-110'}`}
                            >
                              {request.currentPrice}
                            </div>
                          </Marker>
                          <Source
                            type="geojson"
                            data={{
                              type: 'Feature',
                              properties: {
                                radius: circleRadius
                              },
                              geometry: {
                                type: 'Point',
                                coordinates: [request.location.longitude, request.location.latitude]
                              }
                            }}
                          >
                            <Layer
                              id={`radius-${request.id}`}
                              type="circle"
                              paint={{
                                'circle-radius': ['get', 'radius'],
                                'circle-color': isSelected ? '#22c55e' : '#000000',
                                'circle-opacity': isSelected ? 0.2 : 0.15,
                                'circle-stroke-width': 2,
                                'circle-stroke-color': isSelected ? '#22c55e' : '#000000',
                                'circle-stroke-opacity': 0.3
                              }}
                            />
                          </Source>
                        </React.Fragment>
                      );
                    }
                  }
                  return null;
                })}
              </Map>
              
              {userLocation && (
                <button
                  onClick={centerMapOnUser}
                  className="absolute bottom-4 right-4 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100 transition-colors"
                >
                  <Navigation2 className="w-6 h-6 text-gray-700" />
                </button>
              )}
            </div>

            {/* Requests List */}
            {requests.map(request => {
              const winLikelihood = calculateWinLikelihood(request.ideaCount, request.currentPrice);
              const isSelected = selectedRequest?.id === request.id;
              
              return (
                <div 
                  key={request.id} 
                  className={`bg-gray-900 rounded-lg p-6 mb-4 transition-all ${isSelected ? 'ring-2 ring-green-500' : ''}`}
                  onClick={() => handleCircleClick(request)}
                >
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <span className="text-gray-300">{request.author}</span>
                      <span className="text-gray-500 text-sm ml-2">posted {request.posted}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-400">win </span>
                      <span className="text-green-400 font-medium">{request.currentPrice}</span>
                      <span className="text-sm text-gray-400">({winLikelihood}% likely)</span>
                    </div>
                  </div>
                  <h3 className="text-xl mb-4">{request.question}</h3>
                  // Continuing App.js from where we left off...

                  <div className="flex justify-between text-sm text-gray-400">
                    <span>Ends in {request.timeLeft}</span>
                    <span>{request.ideaCount} Ideas</span>
                  </div>
                  {request.scope === 'local' && (
                    <div className="mt-2 text-sm text-gray-400">
                      <span>Local request • {request.customBounds ? 'Custom region' : `${request.radius} mile radius`}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
        
        {/* Post Request Button */}
        <div className="fixed bottom-6 right-6">
          <button 
            onClick={() => {
              if (!userLocation) {
                getUserLocation();
              } else {
                setIsPostModalOpen(true);
              }
            }}
            className="bg-orange-500 text-white rounded-full px-6 py-3 shadow-lg hover:bg-orange-600 transition-colors"
          >
            {!userLocation ? 'Enable Location to Post' : 'Post Request'}
          </button>
        </div>

        {/* Post Request Modal */}
        <PostRequestModal 
          isOpen={isPostModalOpen}
          onClose={() => setIsPostModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default App;